.profile-header {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  // flex-shrink: 0;
  position: static;
  box-shadow: none;
  backdrop-filter: none;
  color: $text-color;
  // top: 0.75rem;
  min-height: auto;
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 24px;
  background-color: transparent !important;
  padding-bottom: 10px;
}

.header-wrapper {
  position: relative;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  .header-inner {
    opacity: 1;
    background: transparent;
    color: inherit;
  }
  .header-wrapper-left {
    opacity: 1;
    background: transparent;
    color: inherit;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    .header-title-text {
      margin: 0px;
      font-size: $font-size-lg;
      line-height: 1.525;
      letter-spacing: 0.0075em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      opacity: 1;
      text-transform: capitalize;
      vertical-align: unset;
      text-decoration: none;
      color: $text-color;
      font-weight: 500;
    }
    span {
      font-size: 13.5px;
      font-weight: 400;
    }
  }
  .header-wrapper-right {
    opacity: 1;
    background: transparent;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: flex-end;
    width: 100%;
    .notifications-dropdown {
      .icon-notifications {
        margin-top: 6px;
        font-size: 22px;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        outline: 0px;
        border: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        text-align: center;
        flex: 0 0 auto;
        border-radius: 50%;
        overflow: visible;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        color: $text-color;
        padding: 5px 6px;
        i {
          color: $text-color;
        }
        .unread-notifications {
          display         : inline-block;
          position        : absolute;
          top             : 5px;
          right           : 5px;
          height          : 7px;
          width           : 7px;
          background-color: $danger;
        }
      }
      .custom-notification {
        font-size: 0.875rem;
        text-align: left;
        color: $text-color;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        position: absolute;
        left: 0;
        overflow: hidden auto;
        min-width: 18px;
        min-height: 16px;
        outline: 0px;
        box-shadow: rgba(20, 20, 20, 0.15) 0rem 0.5rem 1.625rem -0.25rem, rgba(20, 20, 20, 0.06) 0rem 0.5rem 0.5625rem -0.3125rem;
        padding: 0.5rem;
        border-radius: $border-radius-lg;
        background-color: $white !important;
        border: none;
        opacity: 1;
        z-index: 9000;
        inset: 0 -10px auto auto !important;;
        ul {
          list-style: none;
          margin: 0px;
          padding: 0px;
          position: relative;
          outline: 0px;
          width: calc(100% + 0px);
          .li {
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            margin: 0px;
            cursor: pointer;
            user-select: none;
            vertical-align: middle;
            appearance: none;
            -webkit-box-pack: start;
            justify-content: flex-start;
            position: relative;
            text-decoration: none;
            white-space: nowrap;
            min-width: 10rem;
            min-height: unset;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            width: 100%;
            padding: 8px 16px;
            border-radius: $border-radius-lg;
            transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            &:hover {
              background-color: $gray-200;
              color: rgb(52, 71, 103);
              text-decoration: none;
            }
            .icon-box {
              width: 2.25rem;
              height: 2.25rem;
              margin-top: 2px;
              margin-right: 16px;
              margin-bottom: 2px;
              border-radius: 0.75rem;
              display: grid;
              place-items: center;
              background-color: $grey;
              img {
                width: 100%;
                border-radius: 0.75rem;
              }
              i {
                width: 1em;
                height: 1em;
                overflow: hidden;
                display: inline-block;
                text-align: center;
                color: $white;
                font-size: 1.25rem !important;
              }
            }
            span.name {
              font-size: 0.855rem;
              line-height: 1.5;
              opacity: 1;
              text-transform: capitalize;
              color: $text-color;
              font-weight: 300;
            }
            span.time {
              margin: 4px 0px 0px;
              font-size: $font-size-xs;
              font-weight: 400;
              line-height: 1.25;
              opacity: 1;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              color: $gray-600;
              span {
                font-weight: 700;
                line-height: 1;
                opacity: 1;
                text-transform: none;
                vertical-align: unset;
                text-decoration: none;
                color: $gray-600;
                i {
                  user-select: none;
                  width: 1em;
                  height: 1em;
                  overflow: hidden;
                  display: inline-block;
                  text-align: center;
                  line-height: 1.2;
                  margin-right: 4px;
                  font-size: inherit !important;
                  color: inherit;
                }
              }
            }
          }
        }
      }
      .veiw-all {
        display: block;
        font-size: $font-size-base;
        font-weight: 400;
        font-family: $font-family-sans-serif !important;
      }
    }
    .profile-dropdown {
      .profile-avatar {
        cursor: default;
      }
      .image-box {
        width: 40px;
        height: 40px;
        min-height: 40px;
        min-width:  40px;
        overflow: hidden;
        img { 
          width: 100%;
          // height: 100%;
          object-fit: fill;
          object-position: center;
        }
      }
    }
  }
}