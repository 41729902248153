.tooltip-wrapper {
  position: relative;
  width: 20px;
  height: 20px;
  &.active {
    .custom_tooltip {
      opacity: 1;
      pointer-events: auto;
    }
  }
  i {
    font-size: $font-size-base * 1.1;
  }
}
.custom_tooltip {
  line-height: 1.4;
  width: 220px;
  opacity: 0;
  pointer-events: none;
  left: 18px;
  top: -4px;
  position: absolute;
  z-index: 10;
  font-size: $font-size-sm * 0.93;
  font-weight: 400;
  transition: all 0.18s ease-out 0.18s;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  color: $text-muted;
}
.custom_tooltip {
  background-color: #f5f5f5;
  box-shadow: $box-shadow-sm;
  padding: 10px;
}