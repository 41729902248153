/* ============
  Arrows
============ */
.snazzy-arrows{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 38px;
  right: 30px;
}
.snazzy-arrows .slick-arrow{
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 44px;
  border-radius: 8px;
  // border: 1px solid $gray-300;
  color: $white;
  background-color: $teal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  z-index: 10;
  overflow: hidden;
  -webkit-transition: all 350ms ease;
  -moz-transition: all 350ms ease;
  -o-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 22px;
}
.snazzy-arrows .slick-arrow::after{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  // background-color: #f1efef;
  z-index: -1;
  border-radius: 8px;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
}
.snazzy-arrows .slick-arrow:hover{
  -webkit-transition-delay: .1s;
       -o-transition-delay: .1s;
          transition-delay: .1s;
  background-color: $gray-200;
  color: $white;
}
.snazzy-arrows .slick-arrow:hover::after{
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}