// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  // border: 1px solid rgb(186, 241, 186) !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  line-height: 1.5;
  color: $text-color;
  background-color: $gray-100;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  text-align: left;
}

.max-width-lg {
  max-width: 1200px;
  margin: auto;
}

a {
  color: #718cde;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #224abe;
  // text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

iframe {
  border: 0;
}

figcaption,
figure,
main,
section {
  display: block;
  margin: 0;
}

main, section {
	// overflow: hidden;
}

img {
  border-style: none;
}

strong{
  font-weight: $font-weight-bold;
}

.form-label {
  margin-bottom: 0;
}

.add-cooperator-form {
  label, .form-label {
    // margin-bottom: 7px;
    // font-weight: 600;
  }
}

button.btn{
  display: inline-block;
  vertical-align: middle;
  // line-height: 1.5;
}

button:focus {
  outline: 0;
  box-shadow: none !important;
}
.page-link:focus {
  box-shadow: none !important;
}

.form-control, select {
  line-height: 1.7;

  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    // border-color: $border-color !important;
  }
}

.text-right {
  text-align: right !important;
  // padding: 0.20rem 0.5rem !important;
}

.text-color {
  color: $text-color;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-italic {
  font-style: italic;
}

p, .p {
	font-family: $font-family-base;
	font-size: $paragraph-font-size;
	font-weight: $font-weight-normal;
	line-height: $paragraph-line-height;
}

.error-message {
  font-size: 12px;
  color: $danger;
}
.error-message-md {
  font-size: $font-size-base;
  color: $danger;
}
.text-error {
  color: $danger;
}

input, select, textarea, .form-control {
  color: $gray-800 !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $gray-900;
}
.f-12 {
  font-size: 12px !important;
}
.side-display {
  border-left: 4px solid $gray-400;
  padding-left: 10px;
  font-weight: 400;
}

.nav-link {
  color: $gray-700;
  &:hover {
    color: $primary;
  }
}
.nav-pills .nav-link.active {
  background-color: lighten($primary, 12%);
}

.react-datepicker, .react-datepicker__month-container  {
  width: 100% !important;
}

.py-33 {
  padding-top: 12px;
  padding-bottom: 12px;
}