.page_fitter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #e5f3ff 0%, $primary 100%);
  animation: gradientAnimation 10s ease infinite;
  .page_content {
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
  }
  img {
    width: 180px;
    height: 180px;
    object-fit: contain;
    // border-radius: 50%;
    margin-bottom: 10px;
  }
  h1 {
    color: #e5f3ff;
    font-size: 34px;
    margin-bottom: 10px;
  }
  .parag1 {
    color: $primary;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .p2 {
    color: #e5f3ff;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  } 
  .ps1 {
    color: $primary;
    font-size: 15px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .downloadBtn {
      display: inline-block;
      margin: 10px;
      padding: 14px 26px;
      text-decoration: none;
      background-color: $primary;
      color: $white;
      border-radius: 5px;
      font-size: 15px;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      border: none;
      border-radius: 30px;
      outline: none;
      font-weight: 400;
      transition: background-color 0.3s ease, color 0.3s ease;

      i{
        color: $white;
        margin-right: 10px;
        font-size: 18px;
      }

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  @media (max-width: 480px) {
    .content {
      margin-bottom: 20px;
    }
    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
    h1 {
      font-size: 20px;
      padding-left: 12px;
      padding-right: 12px;
    }
    .parag1 {
      font-size: 16px;
    }
    .p2 {
      font-size: 15px;
    } 
    .ps1 {
      font-size: 14px;
    }
    .buttons {
      .downloadBtn {
        font-size: 13px;
        padding: 10px 22px;
      }
    }
  }
}

@keyframes backgroundAnimation {
  0% {
    background-position: center;
  }
  50% {
    background-position: top;
  }
  100% {
    background-position: center;
  }
}