
.login-auth.register {
  .login-container {
    max-width: 800px;
  }
}

.login-auth {
  display: grid;
  place-items: center;
  height: 100vh;
  overflow: auto;
  padding: 0 10px;

  .login-container {
    background-color: $gray-200;
    border: 1px solid $gray-300;
    border-radius: $border-radius;
    max-width: 430px;
    width: 100%;
    padding: 20px 30px 40px 30px;
    text-align: center;
    font-family: $font-family-alt;
    margin: 50px 0;

    img {
      width: 110px;
      margin-bottom: 12px;
    }

    h3.login-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: -1.5px;
    }

    p.subtitle {
      margin-bottom: 28px;
      font-size: 14px;
      font-family: $font-family-alt;
      color: $text-muted;
    }

    .login-form {
      text-align: left;
      
      label {
        font-size: 13px;
        margin-bottom: 6px;
      }

      p {
        font-family: $font-family-alt;
        font-size: 13px;
        margin-bottom: 10px;
        text-align: center;
        color: $text-muted;
      }

      button.btn{
        line-height: 2.4;
      }
    }
  }
}

.password-wrapper {
  position: relative;

  .password-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 1.2rem;
    transform: translateY(-50%);
    width: 1.2rem;
    transition: color 0.2s;

    i {
      font-size: 20px;
      color: $text-muted;
    }
  }
}
.form-group.password-wrapper {
  .password-icon {
    top: 70%;
    right: 1.2rem;
    transform: translateY(-60%);
  }
}

.password-meter {
  border: 1px solid $border-color;
  border-radius: 3px;
  background-color: $gray-400;
  padding: 5px 20px;

  p.text-head {
    font-size: 14px;
    text-align: left !important;
  }
  ul li {
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
  .login-auth {
    .login-container {
      padding: 20px 15px 40px 15px;

      img {
        width: 100px;
      }

      h3.login-title {
        font-size: 14px;
      }
    }
  }
}