.table {
  thead th {
    padding-top   : .75rem;
    padding-bottom: .75rem;
    text-transform: uppercase;
    font-weight   : 600;
    border-top    : 0;
    color: $text-color;
  }

  &.align-items-center {
    td, th {
      vertical-align: middle;
    }
  }
  td,th {
    white-space: nowrap;
    overflow: visible !important;
  }
}
.table-responsive {
  overflow-x: visible;
  overflow-y: visible;
}
.table-hover {
  tr {
    @include transition($transition-base);
  }
}
.card {
  .table {
    margin-bottom: 0;
  }
}

.profile_contribution-table {
  min-height: 300px;
  height: 100%;
  th {
    min-width: 120px;
    max-width: 120px;
    width: 120px;
    font-weight: 400 !important;
    color: $text-muted !important;
    letter-spacing: 0.0290em;
    // &:first-child {
    //   text-align: left;
    //   min-width: 140px;
    //   max-width: 180px;
    //   width: 180px;
    // }
    // &:nth-child(2) {
    //   min-width: 20px;
    //   max-width: 20px;
    //   width: 20px;
    // }
  }
  td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    vertical-align: middle;
    color: $text-color;
    font-weight: 400;
    border-bottom: 1px solid $border-color;
    // &:first-child {
    //   text-align: left;
    //   min-width: 140px;
    //   max-width: 180px;
    //   width: 180px;
    // }
    // &:nth-child(2) {
    //   text-align: center;
    //   min-width: 20px;
    //   max-width: 20px;
    //   width: 20px;
    // }
  }
  // .contrib_header {
  //   .date {
	// 		margin: 0px;
	// 		font-size: $font-size-base;
	// 		line-height: 1.5;
	// 		letter-spacing: 0.02857em;
	// 		color: $text-color;
	// 		font-weight: 500;
	// 	}
	// 	.subtitle {
	// 		margin: 0px;
	// 		font-size: $font-size-sm;
	// 		line-height: 1.25;
	// 		letter-spacing: 0.03333em;
	// 		color: $text-muted;
	// 		font-weight: 400;
	// 	}
  // }
  // .amount {
  //   margin: 0px;
  //   font-size: $font-size-base;
  //   line-height: 1.5;
  //   // letter-spacing: 0.02857em;
  //   color: $text-color;
  //   font-weight: 400;
  // }
  // .icon-set {
  //   display: flex;
  //   -webkit-box-align: center;
  //   align-items: center;
  //   line-height: 0;
  //   opacity: 1;
  //   background: transparent;
  //   color: $text-color;
  //   i {
  //     user-select: none;
  //     overflow: hidden;
  //     display: inline-block;
  //     flex-shrink: 0;
  //     font-size: $font-size-base;
  //   }
  // }
}
// @include media-breakpoint-down(lg) { 
//   .profile_contribution-table {
//     thead {
//       visibility: hidden;
//       height: 0;
//       position: absolute;
//       top: -9999px;
//       left: -9999px;
//     }
//     tr {
//       display: block;
//       margin-bottom: .625em;
//     }
//     td {
//       min-width: 100%;
//       max-width: 100%;
//       width: 100%;
//       border-bottom: 0px solid $border-color !important;
//       display: block;
//       font-size: 0.8125rem;
//       text-align: right;
//       &:first-child {
//         min-width: 100%;
//         max-width: 100%;
//         width: 100%;
//       }
//       &:nth-child(2) {
//         text-align: center;
//         min-width: 100%;
//         max-width: 100%;
//         width: 100%;
//       }
//       &::before {
//         content: attr(data-label);
//         float: left;
//         font-weight: bold;
//         text-transform: uppercase;
//         font-weight: 500 !important;
//         font-size: 14px;
//         color: $text-color !important;
//         letter-spacing: 0.02957em;
//       }
//     }
//   }
  // .profile_contribution-table td::before {
  //   content: attr(data-label);
  //   float: left;
  //   font-weight: bold;
  //   text-transform: uppercase;
  // }
  // .profile_contribution-table td:last-child {
  //   border-bottom: 1px solid;
  // }
// }

// @media only screen and (max-width: 760px),
// (min-device-width: 768px) and (max-device-width: 1024px)  {
//   .profile_contribution-table {
//     display: block;
//     thead, tbody, th, td, tr { 
//       display: block; 
//     }
//     thead tr { 
//       // position: absolute;
      // top: -9999px;
      // left: -9999px;
//       display: none;
//     }
//     tr { border: 1px solid #ccc; }
//     td { 
// 		/* Behave  like a "row" */
//       border: none;
//       border-bottom: 1px solid #eee; 
//       position: relative;
//       padding-left: 50%; 
//       &::before {
//         /* Now like a table header */
//         position: absolute;
//         /* Top/left values mimic padding */
//         top: 6px;
//         left: 6px;
//         width: 45%; 
//         padding-right: 10px; 
//         white-space: nowrap;
//       }
//     }
//     td:nth-of-type(1):before { content: "First Name"; }
//     td:nth-of-type(2):before { content: "Last Name"; }
//     td:nth-of-type(3):before { content: "Job Title"; }
//     td:nth-of-type(4):before { content: "Favorite Color"; }
//     td:nth-of-type(5):before { content: "Wars of Trek?"; }
//     td:nth-of-type(6):before { content: "Secret Alias"; }
//     td:nth-of-type(7):before { content: "Date of Birth"; }
//     td:nth-of-type(8):before { content: "Dream Vacation City"; }
//     td:nth-of-type(9):before { content: "GPA"; }
//     td:nth-of-type(10):before { content: "Arbitrary Data"; }
//   }
// }