@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.idea-preloader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 99999;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}
.idea-preloader.hidden {
  opacity: 0;
  visibility: hidden;
}
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $primary;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}



// // ------ Loading -------
// // -------------------------
// @keyframes loadingE {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// @keyframes loadingD {
//   0% {
//     transform: rotate(0deg);
//   }
//   50% {
//     transform: rotate(180deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// @keyframes loadingF {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// .load-wrapp {
//   width: 100%;
//   // padding: 5px 0px;
//   text-align: center;
//   transition: all 1s;

//   .load-5 {
//     .ball-holder {
//       animation: loadingE 1.3s linear infinite;
//     }

//     .ring-2 {
//       position: relative;
//       width: 45px;
//       height: 45px;
//       margin: 0 auto;
//       border: 4px solid darken($body-bg, 20%);
//       border-radius: 100%;

//       .ball-holder {
//         position: absolute;
//         width: 12px;
//         height: 35px;
//         left: 17px;
//         top: 0px;
      
//         .ball {
//           position: absolute;
//           top: -11px;
//           left: 0;
//           width: 16px;
//           height: 16px;
//           border-radius: 100%;
//           background: theme-color(primary);
//         }
//       }
//     }
//   }

//   .load-6 {
//     .letter-holder {
//       padding: 0px;

//       .letter {
//         float: left;
//         font-size: 13px;
//         color: inherit;
//       }

//       .letter {
//         animation-name: loadingF;
//         animation-duration: 1.6s;
//         animation-iteration-count: infinite;
//         animation-direction: linear;
//       }

//       .l-1 {
//         animation-delay: 0.48s;
//       }
//       .l-2 {
//         animation-delay: 0.6s;
//       }
//       .l-3 {
//         animation-delay: 0.72s;
//       }
//       .l-4 {
//         animation-delay: 0.84s;
//       }
//       .l-5 {
//         animation-delay: 0.96s;
//       }
//       .l-6 {
//         animation-delay: 1.08s;
//       }
//       .l-7 {
//         animation-delay: 1.2s;
//       }
//       .l-8 {
//         animation-delay: 1.32s;
//       }
//       .l-9 {
//         animation-delay: 1.44s;
//       }
//       .l-10 {
//         animation-delay: 1.56s;
//       }
//     }
//   }

//   .load-4 {
//     .ring-1 {
//       animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
//     }
//     .ring-1 {
//       width: 10px;
//       height: 10px;
//       margin: 0 auto;
//       padding: 15px;
//       border: 5px dashed theme-color(primary);
//       border-radius: 100%;
//     }
//   }
// }