@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

// Change variables here
@import "variables";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

/* === Icon fonts === */
@import '~font-awesome/css/font-awesome.min.css';

// Utilities
@import "reboot";
@import "general";

// Components
@import "components/modal";
@import "components/preloader";
@import "components/dropdown";
@import "components/badge";
@import "components/accordion";
@import "components/darkmode";
@import "components/error";
@import "components/card";
@import "components/avatar";
@import "components/table";
@import "components/tooltip";
@import "components/arrowslide";

// Pages
@import "pages/home";
@import "pages/login";
@import "pages/cooperators";
@import "pages/dashboard/sidebar";

@import "pages/profile/sidebar";
@import "pages/profile/header";
// @import "pages/profile/footer";
@import "pages/profile/main";