// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// Shades of grey
$white:    #ffffff;
$gray-100: #fcffff;
$gray-200: #fbffff;
// $gray-200: #eaecf4;
$gray-300: #eaecf4;
$gray-400: #d1d3e2;
$gray-500: #b7b9cc;
$gray-600: #858796;   
$gray-700: #6e707e;   
$gray-800: #5a5c69;   
$gray-900: #2b343f;
$dark    : #5a5c69; 
$black   : #000526;
$grey    : #698fcc;
$text-color: #344767;

// fusv-disable
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

// Generic colors
$blue:    #0b55d4;
$indigo:  #6610f2;
$purple:  #8965e0;
$pink:    #db4d9b;
$red:     #eb4a3c;
$orange:  #fd7e14;
$brown:   #b9a084;
$yellow:  #f4b923;
$green:   #329a74;
// $green:   #5bbd99;
$teal:    #3799fa;
$cyan:    #36b9cc;

// scss-docs-start colors-map   7ad4b3
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);

$primary:       #00a682;
// $primary:       $blue;
$purple:        $purple;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$gray:          $gray-600;
$tertiary:      #296450;


// Brands colors
$facebook:      #3b5999;
$whatsapp:      #60ddaf;
$twitter:       #1da1f2;
$google:        #DB4337;
$instagram:     #e4405f;
$pinterest:     #bd081c;
$youtube:       #cd201f;
$slack:         #3aaf85;
$dribbble:      #ea4c89;
$github:        #222222;
$dropbox:       #1E90FF;
$twitch:        #4B367C;
$paypal:        #ecb32c;
$behance:       #0057ff;
$reddit:        #E84422;

// Brand colors
$brand-colors: ();
$brand-colors: map-merge((
  "facebook":     $facebook,
  "whatsapp":     $whatsapp,
  "twitter":      $twitter,
  "google":       $google,
  "instagram":    $instagram,
  "pinterest":    $pinterest,
  "youtube":      $youtube,
  "slack":        $slack,
  "dribbble":     $dribbble,
  "dropbox":      $dropbox,
  "twitch":       $twitch,
  "paypal":       $paypal,
  "behance":      $behance,
  "reddit" :      $reddit,  
  "github":       $github
), $brand-colors);

// Theme colors
$theme-colors: ();
$theme-colors: map-merge((
  "primary":      $primary,
  "secondary":    $secondary,
  "tertiary":     $tertiary,
  "light":        $light,
  "success":      $success,
  "info":         $info,
  "warning":      $warning,
  "danger":       $danger,
  "white":        $white,
  "gray":         $gray,
  "indigo":       $indigo,
  "dark":         $dark,
  "black":        $black,
  "blue":         $blue,
  "orange":       $orange,
  "pink":         $pink,
  "cyan":         $cyan,
  "purple":       $purple,
  "gray-100":     $gray-100,
  "gray-200":     $gray-200,
  "gray-300":     $gray-300,
  "gray-400":     $gray-400,
  "gray-500":     $gray-500,
  "gray-600":     $gray-600,
  "gray-700":     $gray-700,
  "gray-800":     $gray-800,
  "facebook":     $facebook,
  "whatsapp":     $whatsapp,
  "twitter":      $twitter,
  "google":       $google,
  "instagram":    $instagram,
  "pinterest":    $pinterest,
  "youtube":      $youtube,
  "slack":        $slack,
  "dribbble":     $dribbble,
  "dropbox":      $dropbox,
  "twitch":       $twitch,
  "paypal":       $paypal,
  "behance":      $behance,
  "reddit" :      $reddit,  
  "github":       $github
), $theme-colors);


// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   3;

// Typography

// Font, line-height, and color for body text, headings, and more.

// font awesome
$font-family-sans-serif:      'Ubuntu', sans-serif;
$font-family-alt:             'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

$font-family-base:            $font-family-sans-serif;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root:              null;
$font-size-base:              0.9rem; // Assumes the browser default, typically `16px`
$font-size-xs:                ($font-size-base * 0.8);
$font-size-md:                ($font-size-base * 1);
$font-size-lg:                ($font-size-base * 1.25);
$font-size-xl:                ($font-size-base * 1.5);
$font-size-xxl:               ($font-size-base * 2);

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-bold:            600;
$font-weight-bolder:          700;
$font-weight-extreme:         800;

$h1-font-size:                $font-size-base * 2.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: .9rem
);


// Links
//
// Style anchor elements.
$link-color:                              lighten($blue, 16%);
$link-decoration:                         none;
$link-hover-color:                        darken($link-color, 15%);
$link-hover-decoration:                   null;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;


// Paragraphs
//
// Style p element.
$paragraph-font-size:         0.9375rem;
$paragraph-font-weight:       300;
$paragraph-line-height:       1.6;

$text-muted:                  #819bb2;

$box-shadow:                  0rem 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.1);
$box-shadow-sm:               0rem 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.07), 0rem 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);;


// $border-color:                #e3e6f0;
$border-radius:               5.6px;
$border-radius-lg:            12px;
$circle-radius:               50%;

$transition-base:             all .2s ease;
$transition-transform:        transform .2s ease;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;
$transition-tabs:             all 0.2s;

$pagination-active-color:           $text-color;
$pagination-active-bg:              $gray-300;
// $pagination-active-border-color:    $green;