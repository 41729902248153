.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}
.border-bottom-primary {
  border-bottom: 0.25rem solid #4e73df !important;
}

.border-left-secondary {
  border-left: 0.25rem solid #858796 !important;
}
.border-bottom-secondary {
  border-bottom: 0.25rem solid #858796 !important;
}

.border-left-success {
  border-left: 0.25rem solid #1cc88a !important;
}
.border-bottom-success {
  border-bottom: 0.25rem solid #1cc88a !important;
}

.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}
.border-bottom-info {
  border-bottom: 0.25rem solid #36b9cc !important;
}

.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}
.border-bottom-warning {
  border-bottom: 0.25rem solid #f6c23e !important;
}

.border-left-danger {
  border-left: 0.25rem solid #e74a3b !important;
}
.border-bottom-danger {
  border-bottom: 0.25rem solid #e74a3b !important;
}

.border-left-light {
  border-left: 0.25rem solid #f8f9fc !important;
}
.border-bottom-light {
  border-bottom: 0.25rem solid #f8f9fc !important;
}

.border-left-dark {
  border-left: 0.25rem solid #5a5c69 !important;
}
.border-bottom-dark {
  border-bottom: 0.25rem solid #5a5c69 !important;
}

.text-xs {
  font-size: 11.2px;
}
.text-sm {
  font-size: 13px;
}
.text-md {
  font-size: 14px;
}
.text-lg {
  font-size: 19.2px;
}
.top-25 {
  top: 25% !important;
}
.end-25 {
  right: 25% !important;
}


// gradient button
.gradient-btn {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  letter-spacing: 0.02857em;
  min-width: 64px;
  // width: 100%;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2.5rem;
  box-shadow: $box-shadow;
  padding: 0.75rem 1.5rem;
  background-image: linear-gradient(310deg, $primary, $tertiary);
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: $white;
  background-size: 150% !important;
  background-position-x: 25% !important;
  &:hover {
    background-image: linear-gradient(310deg, $tertiary, $primary);
    transform: scale(1.04);
  }
  i {
    font-size: 0.91rem;
  }
}
.gradient-btn-danger {
  background-image: linear-gradient(310deg, $danger, $pink) !important;
  &:hover {
    background-image: linear-gradient(310deg, $pink, $danger) !important;
  }
}
.gradient-btn-blue {
  background-image: linear-gradient(310deg, $blue, $teal) !important;
  &:hover {
    background-image: linear-gradient(310deg, $teal, $blue) !important;
  }
}
.gradient-btn-gray {
  background-image: linear-gradient(310deg, $gray-400, $gray-500) !important;
  &:hover {
    background-image: linear-gradient(310deg, $gray-500, $gray-400) !important;
  }
}

// Custom Swal Styles
.swal2-popup {
  width: 28em !important;

  &.swal2-toast {
    .swal2-title {
      font-size: 15.5px !important;
      font-weight: 400 !important;
    }
  }

  .swal2-title {
    font-size: 20px !important;
    color: $text-color !important;
  }
  .swal2-html-container {
    font-size: 15px !important;
    line-height: 20px !important;
    // color: $text-muted;
  }

  .swal2-select {
    width: auto !important
  }

  .swal2-actions {
    .swal2-styled  {
      @extend .btn;
      color: $white;
      padding-left: 26px !important;
      padding-right: 26px !important;
      &:hover {
        color: $white;
      }
    }
    .swal2-confirm {
      // @extend .btn-primary;
      // background-color: $success;
    }
    .swal2-deny {
      
    }
    .swal2-cancel {
      // @extend .btn-secondary;
      // background-color: $secondary !important;
    }
  }
}

.joshua-error {
  display: flex;
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
  height: calc(100vh - 125px);
}
.watermark {
  position: relative;

  &::after{
    content: "";
    display: block;
    pointer-events: none;
    width: 210px;
    height: 150px;
    position: absolute;
    top: 0px;
    right: 30px;      
    background-image: url('https://cdn.iconscout.com/icon/premium/png-256-thumb/cat-131-378998.png');
    background-size: auto;
    background-position: 30px 30px;
    background-repeat: no-repeat;
    opacity: 0.1;
  }
}