// .navbar-theme-primary {
//   background-color: $primary;
//   .navbar-toggler {
//     border-color: $white;
//     background-color: $white;
//   }
// }

.sidebar-divided {
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.0625rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 0.3;
  background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important;
}

// Profile Sidebar Section
.sidebar-transition {
  width: 250px;
  white-space: nowrap;
  border: none;
}
@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .slide-down {
    animation: slide-down 0.2s ease;
  }
}
.profile-sidebar {
  background: transparent;
  padding: 1rem;
  animation: slide-down 0.3s ease;
  color: $text-color;
  display: flex;
  flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1031;
	overflow-x: hidden;
  overflow-y: hidden;
  // overscroll-behavior: contain;
  // scrollbar-width: none; /* For Firefox */
  // -ms-overflow-style: none; /* For Internet Explorer and Edge */
  outline: 0px;
  width: 300px;
  @media screen and (max-width: 450px) {
    width: 100%;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }     
}
.profile-sidebar-inner {
  width: 100%;
  height: calc(100vh - 2rem);
  backdrop-filter: saturate(200%) blur(1.875rem);
  background-color: $white;
  border: 0.5px solid $border-color;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  transform: translateX(0px);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow: hidden;

  .sidebar-head {
    padding: 24px 32px 8px;
    background: transparent;
    width: 100%;
    @media screen and (max-width: 768px) {
      padding-left: 22px;
      padding-right: 45px;
    }
    .brand-inner {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 8px;
      a {
        display: block;
        // -webkit-box-align: center;
        // text-decoration: none;
        max-width: 55px;
        width: 100%;
        height: 55px;
        overflow: hidden;
        border: 1px solid $border-color;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: fill;
          object-position: center;
        }
      }
      .sidebar-brand {
        width: 100%;
        overflow: hidden;
        word-spacing: -0.0625rem;
        transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        h6 {
          margin: 0px;
          font-size: $font-size-base;
          line-height: 1.5;
          letter-spacing: 0.01557em;
          opacity: 1;
          text-transform: none;
          vertical-align: unset;
          text-decoration: none;
          color: $text-color;
          font-weight: 600;
        }
      }
    }
    .sidebar-close {
      position: absolute;
      top: 25px;
      right: 15px;
      opacity: 1;
      background: transparent;
      cursor: pointer;
      @media screen and (min-width: 450px) {
        display: none;
      } 
      .menu-close-times {
        width: 30px;
        height: 20px;
        position: relative;
        transition: 300ms ease-in-out;
        background: none;
        // margin-top: 10px;
        .close-times-bar {
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          background: $text-color;
          border-radius: 1px;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: 300ms ease-in-out;
          &.close-bar-top {
            top: inherit;
            transform: rotate(135deg);
          }
          &.close-bar-bottom {
            top: inherit;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  .sidebar-nav-body {
    padding-bottom: 90px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sidebar-nav {
    position: relative;
    a.active {
      li {
        .nav-inner {
          .icon {
            background: $success;
            i { color: #e2f5f1 }
          }
          .nav-link-text {
            span { 
              font-weight: 500;
              color: $success;
            }
          }
        }
      }
    }

    a {
      li {
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding-top: 0px;
        padding-bottom: 0px;
        .nav-inner {
          opacity: 1;
          background: transparent;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          width: 100%;
          padding: 0.675rem 0.8rem 0.675rem 1rem;
          margin: 0px 1rem;
          border-radius: 0.5rem;
          cursor: pointer;
          user-select: none;
          white-space: nowrap;
          box-shadow: none;
          box-shadow: none;
          transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          &:hover{ 
            background: #e9f5f2;
            padding-left: 25px;
  	        transition: padding-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
          .icon {
            color: rgba(0, 0, 0, 0.54);
            flex-shrink: 0;
            min-width: 2rem;
            min-height: 2rem;
            border-radius: 0.5rem;
            display: grid;
            place-items: center;
            box-shadow: $box-shadow;
            transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            background: rgb(233, 236, 239);
            i { color: $success }
          }
          .nav-link-text {
            opacity: 1;
            max-width: 100%;
            margin-left: 0.8rem;
            transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            flex: 1 1 auto;
            min-width: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
            margin-left: 0.8rem;
            color: $text-color;
            span {
              margin: 0px;
              letter-spacing: 0.00938em;
              display: block;
              line-height: 0;
              font-weight: 300;
              color: $text-color;
            }
          }
        }
      }
    }
  }

  .bottom-wrapper {
    padding-top: 16px;
    margin: auto 16px 16px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    .bottom-up-box {
      color: rgba(0, 0, 0, 0.87);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow-wrap: break-word;
      background-color: rgb(255, 255, 255);
      background-clip: border-box;
      border: 1px solid $border-color;
      min-width: auto;
      background-image: url(https://demos.creative-tim.com/soft-ui-dashboard-react/static/media/white-curved.a5b7db662b0b14ab5534.jpeg);
      background-position: 50% center;
      background-size: cover;
      border-radius: 1rem;
      box-shadow: none;
      max-height: 12rem;
      transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      .up-inner {
        margin-top: 0px;
        margin-bottom: 0px;
        color: rgb(255, 255, 255);
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        padding: 16px;
        &::after {
          content: "";
          background-image: linear-gradient(310deg, $primary, $teal);
          display: block;
          height: 100%;
          width: 100%;
          border-radius: 1rem;
          position: absolute;
          top: 0px;
          left: 0px;
          opacity: 0.65;
          z-index: -1;
        }
        .up-inner-one {
          width: 2rem;
          height: 2rem;
          margin-bottom: 16px;
          opacity: 1;
          background: $white;
          color: $gray-900;
          border-radius: 0.5rem;
          box-shadow: $box-shadow;
          display: grid;
          place-items: center;
          transition: margin 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        .up-inner-two {
          line-height: 1;
          opacity: 1;
          background: transparent;
          color: $text-color;
          h6 {
            margin: 0px;
            font-size: 1rem;
            line-height: 1.625;
            font-weight: 500;
            letter-spacing: 0.0075em;
            opacity: 1;
            text-transform: none;
            vertical-align: unset;
            text-decoration: none;
            color: $white;
          }
          .inner-two-text {
            margin-bottom: 14.6px;
            margin-top: -8px;
            opacity: 1;
            background: transparent;
            color: $text-color;
            span {
              margin: 0px;
              font-size: 0.75rem;
              line-height: 1.25;
              letter-spacing: 0.03333em;
              opacity: 1;
              text-transform: none;
              vertical-align: unset;
              text-decoration: none;
              color: $white;
              font-weight: 500;
            }
          }
          .doc-btn {
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            outline: 0px;
            border: 0px;
            margin: 0px;
            cursor: pointer;
            vertical-align: middle;
            appearance: none;
            text-decoration: none;
            letter-spacing: 0.02857em;
            min-width: 64px;
            width: 100%;
            display: inline-flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            font-weight: 700;
            border-radius: 0.5rem;
            line-height: 1.4;
            text-align: center;
            text-transform: uppercase;
            user-select: none;
            transition: all 150ms ease-in 0s;
            box-shadow: $box-shadow-sm;
            min-height: 2rem;
            padding: 0.5rem 2rem;
            font-size: 0.75rem;
            background-image: initial;
            background-position-y: initial;
            background-repeat: initial;
            background-attachment: initial;
            background-origin: initial;
            background-clip: initial;
            background-color: $white;
            color: $text-color;
            background-size: 150% !important;
            background-position-x: 25% !important;
          }
        }
      }
    }
  }
}


.navbar-theme-primary, .home-navbar {
  .navbar-toggler {
    width: 36px;
    height: 22px;
    position: relative;
    transition: 300ms ease-in-out;
    background: none;
  }

  .navbar-toggler,
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
    position: relative;
  }
  .navbar-toggler span {
    margin: 0;
    padding: 0;
  }
  .toggler-icon {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $text-color;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 300ms ease-in-out;
  }
  .middle-bar {
    margin-top: 0px;
  }

  /* State when the navbar is collapsed */
  .navbar-toggler.hide .top-bar {
    position: absolute;
    top: 0px;
    transform: rotate(0deg);
  }
  .navbar-toggler.hide .middle-bar {
    opacity: 1;
    position: absolute;
    top: 11px;
    filter: alpha(opacity=100);
  }
  .navbar-toggler.hide .bottom-bar {
    position: absolute;
    top: 22px;
    transform: rotate(0deg);
  }

  /* when navigation is clicked */
  .navbar-toggler .top-bar {
    top: inherit;
    transform: rotate(135deg);
  }

  .navbar-toggler .middle-bar {
    opacity: 0;
    top: inherit;
    filter: alpha(opacity=0);
  }

  .navbar-toggler .bottom-bar {
    top: inherit;
    transform: rotate(-135deg);
  }

  /* Color of 3 lines */
  .navbar-toggler.hide .toggler-icon {
    background: linear-gradient( 263deg, rgba(252,74,74,1) 0%, rgba(0,212,255,1) 100% );
  }
}

.home-navbar {
  // background: $gray-100;
  .navbar-nav {
    .nav-link {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 15px;
      font-weight: 400;
    }
  }
}