.sm-avatar{
  height: 2rem;
  width:  2rem;
  // min-height: 2.1rem;
  // min-width:  2.1rem;
  object-fit: cover;
}

.md-avatar{
  height: 2.5rem;
  width:  2.5rem;
  min-height: 2.5rem;
  min-width:  2.5rem;
}

.lg-avatar{
  height: 3.5rem;
  width:  3.5rem;
  min-height: 3.5rem;
  min-width:  3.5rem;
  font-size: $font-size-xs;

  @include media-breakpoint-down(lg) {
    height: 2rem;
    width:  2rem;
    min-height: 2rem;
    min-width:  2rem;
  }
}

.xl-avatar{
  height: 16rem;
  width:  16rem;
  min-height: 16rem;
  min-width:  16rem;

  @include media-breakpoint-down(lg) {
    height: 10rem;
    width:  10rem;
    min-height: 10rem;
    min-width:  10rem;
  }
  @include media-breakpoint-down(sm) {
    height: 7rem;
    width:  7rem;
    min-height: 7rem;
    min-width:  7rem;
  }
}