.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}


// custom badge
.custom-badge {
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  font-weight: 400;
  display: inline-block;
  width: max-content;
  .custom-badge_inner {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    .badge_text {
      height: auto;
      padding: 0.33em 0.775em 0.33em;
      font-size: 0.65rem;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border: none;
      border-radius: 0.25rem;
      color: $text-color;
      position: relative;
      transform: none;
      display: flex;
      flex-flow: row wrap;
      -webkit-box-pack: center;
      place-content: center;
      -webkit-box-align: center;
      align-items: center;
      font-weight: 400;
      min-width: 20px;
      z-index: 1;
      transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transform-origin: 100% 0%;
      &.secondary {
        background: linear-gradient(310deg, rgb(98, 117, 148), rgb(168, 184, 216));
        color: $white;
      }
      &.success {
        background: linear-gradient(310deg, $success, #40d051);
        color: $white;
      }
      &.danger {
        background: linear-gradient(310deg, $danger, #eb756a);
        color: $white;
      }
      &.warning {
        background: linear-gradient(310deg, $warning, #eebb39);
        color: $white;
      }
    }
  }
}
