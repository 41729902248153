.cooperators-edit {
  .card-header-tabs .nav-link{
    transition: all 150ms ease-in 0s;
  }
  .card-header-tabs .nav-link.active {
    color: $primary;
    font-weight: 700;
  }
  .cooperator-leftside {
    .list-group{
      .list-group-item{
        font-size: 14px;
        font-weight: 400;
        &.active{
          background-color: $gray-200;
          color: $primary;
          border-color: $border-color;
        }
      }
    }
  }
}

.cooperation_data {
  .badge {
    font-size: 12px;
  }
  h6 {
    font-size: 17.7px;
    font-weight: 700;
  }
  .personal_info_details-list {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
    opacity: 1;
    background: transparent;
    color: $text-color;
    span {
      margin: 0px;
      font-size: 0.875rem;
      line-height: 1.5;
      letter-spacing: 0.02857em;
      opacity: 1;
      text-transform: capitalize;
      vertical-align: unset;
      text-decoration: none;
      color: $text-color;
      font-weight: 500;
      &.name {
        font-size: 0.875rem;
        line-height: 1.5;
        text-transform: none;
        color: $text-muted;
        font-weight: 400;
        // margin-left: 8px;
      }
    }
  }
}