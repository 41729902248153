#wrapper {
  display: flex;
}

#wrapper #content-wrapper {
  background-color: $gray-100;
  width: 100%;
  overflow-x: hidden;
}

#wrapper #content-wrapper #content {
  flex: 1 0 auto;
}

.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  // object-position: center;
}

.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative;
}

.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: .25rem;
  margin-top: -.25rem;
  top: 35%;
}

.collapse:not(.show) {
  display: none;
}
// Sidebar Icon Only
.sidebar-icon-only {
  // @media (min-width: 992px){
    .sidebar {
      overflow: visible;
      .nav-item{
        .collapse {
          display: none;
        }
      }
      .nav-item.hover-open{
        .collapse {
          display: block;
          position: absolute;
          left: 6.5rem;
          width: 190px;
          .collapse-inner {
            border-radius: 0 0.35rem 0.35rem 0;
          }
        }
      }
    }
  // }
}

// Sidebar Menu Section
.sidebar {
  width: 6.5rem;
  min-height: 100vh;
  transition: background-color 0.5s ease-in-out;
  .nav-item {
    position: relative;
    &:last-child {
      margin-bottom: 1rem;
    }
    &.active .nav-link {
      font-weight: 600;
    }
    .nav-link {
      text-align: center;
      padding: 0.85rem 1rem;
      width: 6.5rem;
      cursor: pointer;
      span {
        font-size: 0.75rem;
        display: block;
        margin-top: 2px;
      }
    }
    .collapse {
      position: absolute;
      left: calc(6.5rem + 1.5rem / 2);
      z-index: 1;
      top: 2px;
      .collapse-inner {
        border-radius: 0.35rem;
        box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
      }
    }
    .collapsing {
      display: none;
      transition: none;
    }
    .collapse .collapse-inner,
    .collapsing .collapse-inner {
      padding: .45rem 0;
      min-width: 10rem;
      font-size: 0.85rem;
      margin: 0 0 1rem 0;
    }
    .collapse .collapse-inner .collapse-header,
    .collapsing .collapse-inner .collapse-header {
      margin: 0;
      white-space: nowrap;
      padding: .5rem 1.5rem;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 0.65rem;
      color: $gray-500;
    }
    .collapse .collapse-inner .collapse-item,
    .collapsing .collapse-inner .collapse-item {
      padding: 0.5rem 0.5rem 0.5rem 1.4rem;
      margin: 0 0.5rem;
      display: block;
      color: $gray-900;
      text-decoration: none;
      border-radius: 0.35rem;
      white-space: nowrap;
      &:hover {
        background-color: $gray-300;
      }
      &:active {
        background-color: $gray-300;
      }
      .active {
        color: #4e73df;
        font-weight: 700;
      }
    }
  }
  .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    padding: 1.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    z-index: 1;
    .sidebar-brand-icon {
      i {font-size: 2rem; }
      img { width: 43px; }
    }
    .sidebar-brand-text {
      display: none;
    }
  }
  hr.sidebar-divider {
    margin: 0 1rem 1rem;
  }
  .sidebar-heading {
    text-align: center;
    padding: 0 1rem;
    font-weight: 800;
    font-size: 0.68rem;
  }  
} 
#sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  cursor: pointer;
  &::after {
    font-weight: 900;
    content: '\f104';
    font-family: 'FontAwesome';
    margin-right: 0.1rem;
  }
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}
.nav-toggled {
  #sidebarToggle::after {
    content: '\f105';
    font-family: 'FontAwesome';
    margin-left: 0.25rem;
  }
}
.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
  #sidebarToggle::after {
    content: '\f105';
    font-family: 'FontAwesome';
    margin-left: 0.25rem;
  }
}

@media (min-width: 768px) {
  .sidebar {
    width: 14rem !important;
    .nav-item {
      .collapse {
        position: relative;
        left: 0;
        z-index: 1;
        top: 0;
        -webkit-animation: none;
        animation: none;
        .collapse-inner {
          border-radius: 0.35rem;
          box-shadow: none;
        }
      }
      .collapsing {
        display: block;
        transition: height 0.15s ease;
      }
      .collapse, .collapsing {
        margin: 0 1rem;
      }
      .nav-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: 1rem;
        width: 14rem;
        i {
          font-size: $font-size-base;
          margin-right: 0.55rem;
        }
        span {
          font-size: $font-size-base;
          display: inline;
        }
      }
    }
    .sidebar-brand {
      .sidebar-brand-icon {
        i { font-size: 2rem; }
        img { width: 43px; }
      }
      .sidebar-brand-text {
        display: inline;
      }
    }
    .sidebar-heading {
      text-align: left;
    }
  }
  .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;

    .nav-item {
      &:last-child {
        margin-bottom: 1rem;
      }
      .accordion-item {
        .accordion-button {
          &::after {
            display: none;
          }
        }
      }
      .collapse {
        position: absolute;
        left: calc(6.5rem + 1.5rem / 2);
        z-index: 1;
        top: 2px;
        -webkit-animation-name: growIn;
        animation-name: growIn;
        -webkit-animation-duration: 200ms;
        animation-duration: 200ms;
        -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
        animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
        .collapse-inner {
          box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
          border-radius: 0.35rem;
        }
      }
      .collapsing {
        display: none;
        transition: none;
      }
      .collapse, .collapsing {
        margin: 0;
      }
      .nav-link {
        text-align: center;
        padding: 0.85rem 1rem;
        width: 6.5rem;
        span {
          font-size: $font-size-xs;
          display: block;
        }
        i {
          margin-right: 0;
          margin-bottom: 2px;
        }
      }
    }
    .sidebar-brand {
      .sidebar-brand-icon {
        i {
          font-size: 2rem;
        }
      }
      .sidebar-brand-text {
        display: none;
      }
    }
    .sidebar-heading {
      text-align: center;
    }
  }
  // .sidebar.toggled .nav-item .nav-link[data-toggle="collapse"]::after {
  //   display: none;
  // }
}

.sidebar-light {
  background-color: $white;
  .sidebar-brand {
    color: $gray-900;
  }
  hr.sidebar-divider {
    border-top: 1px solid $gray-500;
  }
  .sidebar-heading {
    color: $gray-900;
  }
  .nav-item {
    .nav-link {
      color: $gray-600;
      i { color: #d1d3e2; }      
      &:active, &:focus, &:hover {
        background-color: #eff5ff;
        color: $primary;
        i {
          color: $primary;
        }
        &::after {
          color: $gray-600;
        }
      }
    }
    .collapse .collapse-inner .collapse-item,
    .collapsing .collapse-inner .collapse-item {
      color: $gray-600;
      // &:hover {
      //   background-color: $white;
      //   color: $primary;
      // }
      // &:active {
      //   background-color: $white;
      //   color: $primary;
      // }
    }
  }
  .nav-item.active {
    .nav-link {
      color: $primary;
      i {
        color: $primary;
      }
    }
  }
}
// .sidebar-light #sidebarToggle {
//   background-color: #eaecf4;
// }
// .sidebar-light #sidebarToggle::after {
//   color: #b7b9cc;
// }
// .sidebar-light #sidebarToggle:hover {
//   background-color: #dddfeb;
// }

.sidebar-dark {
  background-color: $primary;
  background-image: linear-gradient(180deg, $tertiary 10%, darken($tertiary, 15%) 100%);
  background-size: cover;

  .sidebar-brand {
    color: $white;
  }
  hr.sidebar-divider {
    border-top: 1px solid $white;
  }
  .sidebar-heading {
    color: rgba($white, 0.4);
  }
  .nav-item {
    .nav-link {
      color: rgba($white, 0.8);
      i {
        color: rgba($white, 0.3);
      }
      &:active, &:focus, &:hover {
        color: $white;
        i {
          color: $white;
        }
      }
    }
  }
  .nav-item.active {
    .nav-link {
      color: #d6e4fa;
      i {
        color: #d6e4fa;
      }
    }
  }
  #sidebarToggle {
    background-color: $primary;
    &::after {
      color: $green;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
}

// Topbar Nav Section
.topbar {
  height: 4.375rem;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  background-color: $white;
  #sidebarToggleTop {
    height: 2.5rem;
    width: 2.5rem;
    &:hover {
      background-color: #eaecf4;
    }
    &:active {
      background-color: $gray-100;
    }
  }
  .navbar-search {
    width: 25rem;
    .input-group-merge {
      position: relative;
      input.form-control {
        font-size: $font-size-base;
        height: auto;
        border-radius: 8px;
        padding-right: 36px;
        background-color: $gray-100;
        border: 0;
        &::placeholder,
        &:-ms-input-placeholder,
        &::-webkit-input-placeholder {
          color: $gray-400;
          font-size: 11px;
          letter-spacing: 1.5px;
        }
      }
      i {
        position: absolute;
        right: 14px;
        top: 9px;
        font-size: $font-size-lg;
        color: $gray-600;
      }
    }
  }
  .nav-item {
    &:focus {
      outline: none;
    }
    .nav-link {
      height: 4.375rem;
      display: flex;
      align-items: center;
      padding: 0 0.75rem;
      position: relative;

      &:focus {
        outline: none;
      }
    }
  }
  .dropdown {
    position: static;
    .dropdown-toggle {
      border: none;
      border-radius: 0;
      background-color: transparent;
      display: block;
      position: relative;
    }

    .dropdown-menu {
      width: calc(100% - 1.5rem);
      right: 0.75rem;
    }
  }
  .dropdown-list {
    padding: 0;
    border: none;
    overflow: hidden;

    .dropdown-header {
      background-color: #4e73df;
      border: 1px solid #4e73df;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      color: #fff;
    }

    .dropdown-item {
      white-space: normal;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-left: 1px solid #e3e6f0;
      border-right: 1px solid #e3e6f0;
      border-bottom: 1px solid #e3e6f0;
      line-height: 1.3rem;
      margin-right: 20px;
      .text-truncate {
        max-width: 5rem;
      }
      &:active {
        background-color: #eaecf4;
        color: #3a3b45;
      }

      .dropdown-list-image {
        position: relative;
        height: 2.5rem;
        width: 2.5rem;

        img {
          height: 2.5rem;
          width: 2.5rem;
        }

        .status-indicator {
          background-color: #eaecf4;
          height: 0.75rem;
          width: 0.75rem;
          border-radius: 100%;
          position: absolute;
          bottom: 0;
          right: 0;
          border: 0.125rem solid #fff;
        }
      }
    }
  }
}
.topbar-divider {
  width: 0;
  border-right: 1px solid $border-color;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}
.topbar-divider-sm {
  width: 0;
  border-right: 1px solid $border-color;
  height: calc(3.375rem - 2rem);
  margin: auto 1rem;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: $gray-400;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: $gray-500
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
  color: $gray-600;
}

@media (min-width: 576px) {
  .topbar {
    .dropdown {
      position: relative;
      .dropdown-menu {
        width: auto;
        right: 0;
      }
    }
    .dropdown-list {
      width: 20rem !important;
      .dropdown-item {
        .text-truncate {
          max-width: 13.375rem;
        }
      }
    }
  }
}
