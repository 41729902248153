// Profile Account Styles
.profile-content {
  opacity: 1;
  background: $gray-100;
  color: $gray-900;
  position: relative;
	overflow: hidden;
	height: 100%;
  @include media-breakpoint-down(md) {
		margin-top: 60px;
	}

	.profile-content-wrapper {
		// display: flex;
		// justify-content: space-between;
		// flex-direction: column;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 20px 24px;
		position: relative;
    opacity: 1;
    background: transparent;
    color: $text-color;

		// .profile-content-wrapper-inner {
		// 	padding-top: 24px;
		// }
	}
}
@media (min-width: 768px) {
	.profile-content {
		margin-left: 316px;
  	transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}
}

.banner {
	padding-top: 30px;
	padding-bottom: 60px;
	margin-top: 90px;
	@media (min-width: 992px) {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
	}
	.banner-head {
		line-height: 54px;
		font-size: calc(1.45rem + 2.4vw) !important;
		@media (min-width: 1200px) {
			font-size: 3.25rem !important;
		}
	}
	.banner-subtext {
		font-size: 20px !important;
	}
	.video-detail {
		box-shadow: 0 0px 30px rgba(24, 23, 23, 0.1) !important;
		background: #bbe5db;
		.btn-success {
			padding-bottom: 10px;
			padding-top: 10px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			i {
				font-size: 18px;
				margin-top: 1px;
			}
		}
		.welcome_back {
			font-size: 15px;
			&:hover {
				color: $tertiary;
			}
		}
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	@keyframes shrink {
		0% {
			transform: scale(0.6);
		}
		50% {
			transform: scale(1);
		}
		100% {
			transform: scale(0.6);
		}
	}
	@keyframes move {
		0% {
			top: 0;
			left: 0;
		}
		25% {
			top: 0;
			left: 100px;
		}
		50% {
			top: 100px;
			left: 100px;
		}
		75% {
			top: 100px;
			left: 0;
		}
		100% {
			top: 0;
			left: 0;
		}
	}
	.card-img-top {
		width: 420px;
		animation:  shrink 50s ease-in-out infinite, spin 50s linear infinite;
		&:hover {
			animation-play-state: paused;
		}
		@media (max-width: 500px) {
			width: 230px;
		}
	}
}

.infosection {
	padding-top: 80px !important;
	padding-bottom: 80px !important;
	@media (min-width: 1200px) {
		padding-bottom: 170px !important;
    padding-top: 120px !important;
	}
}

.commodity-home_page {
	background: #f3f4f8;
	padding-top: 60px !important;
	padding-bottom: 60px !important;
	@media (min-width: 1200px) {
		padding-bottom: 140px !important;
    padding-top: 100px !important;
	}
}

.custom_card {
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: 0px;
	overflow-wrap: break-word;
	background-color: $white;
	background-clip: border-box;
	border: 0.5px solid $border-color;
	border-radius: 1rem;
	// border-radius: 10rem !important;
	overflow: hidden;
	// height: 100%;

	.custom_card-header {
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
		padding: 18px 24px 12px 24px;
		opacity: 1;
		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: flex-start;
		}
		h6 {
			margin: 0px;
			font-size: 1rem;
			line-height: 1.625;
			font-weight: 500;
			letter-spacing: 0.0075em;
			opacity: 1;
			text-transform: none;
			vertical-align: unset;
			color: $text-color;
		}
		.custom-profile-dropdown {
			padding-left: 16px;
			padding-right: 16px;
			.dropdown-toggle {
				padding: 0;
				font-family: $font-family-base !important;
				font-weight: 400;
				background-color: transparent !important;
				color: $text-muted !important;
				border: none;
				font-size: 0.927rem !important;
			}
			.dropdown-menu {
				border-radius: 12px;
				outline: 0px;
				border: none;
				box-shadow: rgba(20, 20, 20, 0.15) 0rem 0.5rem 1.625rem -0.25rem, rgba(20, 20, 20, 0.06) 0rem 0.5rem 0.5625rem -0.3125rem;
				margin: 0;
				padding: 8px;
				max-height: 300px;
				overflow: scroll;
				.dropdown-item {
					padding: 0.3rem 1rem;
					border-radius: 0.5rem;
					font-size: 0.864rem;
					letter-spacing: 0.00938em;
					color: $text-color;
					transition: background-color 300ms ease 0s, color 300ms ease 0s;
					&:hover {
						background-color: $gray-200 !important;
						color: rgb(52, 71, 103);
						text-decoration: none;
					}
				}
			}
		}
	}
	.custom_card-body {
		padding: 0 24px 20px 24px;
		color: $text-color;
		height: 100%;
		.mini-filter {
			display: none;
			.date-label {
				font-size: 12px;
				color: $text-muted;
			}
			.form-control {
				padding: 0 auto;
				border-top: none;
				border-right: none;
				border-left: none;
			}
		}
		@include media-breakpoint-down(sm) {
			.mini-filter.show {
				display: block;
			}
		}
	}
}

.commodity-card {
	.img-holder {
		height: 235px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	.h6 {
		&:hover {
			color: $primary
		}
	}
}

.profile-settings-nav {
	@media (max-width: 768px) {  /* Customize the breakpoint as needed */
		.navbar-nav {
			// .nav-tabs {
				// background: red;
				// position: absolute;
				// min-width: 500px;
				// top: 100%;  /* Position the dropdown below the nav link */
				// right: 0;
				// left: 0;
				// width: 100%;  /* Make the dropdown full width */
				// z-index: 100;  /* Adjust the z-index as needed */
			}
		// }
	}
	.nav-tabs {
		border: none;
		// margin-top: 13px;
		.nav-item {
			margin-right: 6px;
			padding-bottom: 10px;
			border-bottom: 2px solid transparent;
			.nav-link {
				padding: 4px 12px;
				border-radius: 0.5rem;
				font-size: 14px;
				color: $white;
				border-color: transparent;
				&.active {
					border: 0.5px solid $white;
					background: $white;
					color: $primary;
				}
				&:hover {
					border: 0.5px solid $white;
				}
			}
		}
	}
}

.profile-settings-img-wrapper {
	width: 133px;
	height: 133px;
	border-radius: 50%;
	border: 1px solid $border-color;
	position: relative;
	img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: cover;
		object-position: center;
	}
	.camera-icon {
		width: 35px;
		height: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $white;
		position: absolute;
		bottom: 4px;
		right: 0px;
		border-radius: 50px;
		border: 2px solid $border-color;
		cursor: pointer;
		i { 
			font-size: 16px;
			color: $text-color;
		}
	}
}

/* ==================
	DASHBOARD SECTION
====================*/
.dash-card {
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: 0px;
	overflow-wrap: break-word;
	background-color: $white;
	background-clip: border-box;
	border: 0.5px solid $border-color;
	border-radius: 1rem;
	.dash-card-inner {
		padding: 16px;
    opacity: 1;
    background: transparent;
		.dash-card-content {
			box-sizing: border-box;
			display: flex;
			flex-flow: row wrap;
			width: 100%;
			-webkit-box-align: center;
			align-items: center;
			.content-detail {
				margin-left: 0px;
				line-height: 1;
				opacity: 1;
				background: transparent;
				color: $text-color;
				.text-head{
					margin: 0px;
					font-size: 15px;
					font-weight: 500;
					line-height: 1.5;
					letter-spacing: 0.02857em;
					opacity: 1;
					text-transform: capitalize;
					vertical-align: unset;
					text-decoration: none;
					color: #5976a7
				}
				h5 {
					margin: 0px;
					font-size: $font-size-lg;
					line-height: 1.375;
					letter-spacing: 0em;
					opacity: 1;
					text-transform: none;
					vertical-align: unset;
					text-decoration: none;
					color: $text-color;
					font-weight: 600;
					span {
						margin: 0px;
						font-size: 12px;
						line-height: 1.5;
						opacity: 1;
						text-transform: none;
						vertical-align: unset;
						text-decoration: none;
						margin-left: 5px;
					}
					span.up {
						color: lighten($success, 8%);
					}
					span.down {
						color: $danger;
					}
				}
			}
			.icon-box {
				width: 3rem;
				height: 3rem;
				margin-left: auto;
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				opacity: 1;
				background: linear-gradient(310deg, lighten($tertiary, 10%), lighten($primary, 12%));
				color: $white;
				border: none;
				border-radius: 0.5rem;
				// box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07%) 0rem 0.125rem 0.25rem -0.0625rem;
				i {
					user-select: none;
					width: 1em;
					height: 1em;
					overflow: hidden;
					display: inline-block;
					text-align: center;
					flex-shrink: 0;
					font-size: 1.25rem !important;
				}
			}
			.icon-box-danger {
				background: linear-gradient(310deg, $pink, lighten($danger, 12%));
			}
			.icon-box-blue {
				background: linear-gradient(310deg, $blue, $teal);
			}
		}
	}
}

.history-item {
	display: block;
	position: relative;
	border-bottom: 0.5px solid $border-color;
	// background-color: #5976a7;
	margin-bottom: 10px;
	padding: 15px 15px;
	&:hover {
		background-color: $gray-200;
	}
	.delete-loan-btn {
		position: absolute;
		top: 15px;
		right: 15px;
		padding: 4px 10px;
		border: 0.5px solid $border-color;
		border-radius: 6px;
		color: $gray-700;
		background: $white;
		outline: none;
		&:hover {
			background-color: $gray-300;
		}
	}	
}

.loan-details-page {
	position: relative;
	.delete-loan-btn {
		position: absolute;
		top: 0px;
		right: 15px;
		padding: 4px 10px;
		border: 0.5px solid $border-color;
		border-radius: 6px;
		color: $gray-700;
		background: $white;
		outline: none;
		&:hover {
			background-color: $gray-300;
		}
	}	
	.loan-details-image-wrapper {
		width: 100%;
		height: 400px;
		border-radius: 3px;
		overflow: hidden;
		img{ 
			width: 100%;
			height: 100%;
			object-fit: contain;
			border-radius: 3px;
		}
	}
}	

.as-guarantor-container {
	.img-round {
		width: 50px;
		height: 50px;
		border-radius: 25px;
		border: 1px solid $border-color;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.img-round.active {
		animation-name: blink;
		animation-duration: 1.5s;
		animation-timing-function: ease-out;
		animation-iteration-count: infinite;
	}
}
@keyframes blink {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}


// ul.savings-list {
// 	display: flex;
// 	flex-direction: column;
//   transition: transform 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
// 	.savings_header {
// 		display: flex;
//     -webkit-box-pack: justify;
//     justify-content: space-between;
//     -webkit-box-align: center;
//     align-items: center;
//     padding: 0 12px 8px;
// 		transition: transform 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
// 		border-bottom: 1px solid $border-color;
// 	}
// 	li.savings_item {
// 		display: flex;
//     -webkit-box-pack: justify;
//     justify-content: space-between;
//     -webkit-box-align: center;
//     align-items: center;
//     padding: 8px 12px;
//     margin-bottom: 8px;
// 		// border-radius: 6px;
// 		transition: transform 300ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
// 		&:nth-child(odd) {
// 			background-color: $gray-100;
// 		}
// 		@include media-breakpoint-down(sm) {
// 			flex-direction: column;
// 			align-items: flex-start;
// 			.leftside {
// 				width: 100%;
// 			}
// 			.rightside {
// 				justify-content: space-between;
// 				width: 100%;
// 			}
// 		}
// 	}
// 	.leftside {
// 		.date {
// 			margin: 0px;
// 			font-size: $font-size-base;
// 			line-height: 1.5;
// 			letter-spacing: 0.02857em;
// 			color: $text-color;
// 			font-weight: 500;
// 		}
// 		.subtitle {
// 			margin: 0px;
// 			font-size: $font-size-sm;
// 			line-height: 1.25;
// 			letter-spacing: 0.03333em;
// 			color: $text-muted;
// 			font-weight: 400;
// 		}
// 	}
// 	.rightside {
// 		display: flex;
// 		-webkit-box-align: center;
// 		align-items: center;
// 		opacity: 1;
// 		.amount {
// 			margin: 0px;
// 			font-size: $font-size-base;
// 			line-height: 1.5;
// 			letter-spacing: 0.02857em;
// 			color: $text-color;
// 			font-weight: 400;
// 			width: 100px;
// 			text-align: right;

// 			&.shares {
// 				width: 140px;
// 			}
// 			&.savings {
// 				width: 140px;
// 			}
// 			&.debit {
// 				width: 140px;
// 			}
// 			&.credit {
// 				width: 140px;
// 			}
// 		}
// 		.icon-set {
// 			display: flex;
// 			-webkit-box-align: center;
// 			align-items: center;
// 			line-height: 0;
// 			margin-left: 28px;
// 			opacity: 1;
// 			background: transparent;
// 			color: $text-color;
// 			i {
// 				user-select: none;
// 				overflow: hidden;
// 				display: inline-block;
// 				text-align: center;
// 				flex-shrink: 0;
// 				font-size: $font-size-base;
// 			}
// 			span {
// 				margin: 0px;
// 				font-size: $font-size-sm;
// 				line-height: 1.5;
// 				letter-spacing: 0.02857em;
// 				opacity: 1;
// 				text-transform: none;
// 				vertical-align: unset;
// 				text-decoration: none;
// 				font-weight: 400;
// 			}
// 		}
// 	}
// }
// /* ==================
// 	PROFILE SECTION
// ====================*/
// .personal_nav {
// 	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// 	overflow: hidden;
// 	display: flex;
// 	flex-direction: column;
// 	min-width: 0px;
// 	overflow-wrap: break-word;
// 	background-clip: border-box;
// 	border: 0px solid rgba(0, 0, 0, 0.125);
// 	border-radius: 1rem;
// 	backdrop-filter: saturate(200%) blur(30px);
// 	background-color: rgba(255, 255, 255, 1);
// 	box-shadow: rgba(255, 255, 255, 0.9) 0rem 0rem 0.0625rem 0.0625rem inset, rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
// 	position: relative;
// 	padding: 16px;
// 	.personal_nav-inner {
// 		box-sizing: border-box;
//     display: flex;
// 		justify-content: space-between;
//     flex-flow: row wrap;
//     -webkit-box-align: center;
//     align-items: center;
// 		width: 100%;
// 		.inner_items {
// 			display: flex;
// 			align-items: center;
// 			.personal_avatar {
// 				position: relative;
// 				display: flex;
// 				-webkit-box-align: center;
// 				align-items: center;
// 				-webkit-box-pack: center;
// 				justify-content: center;
// 				flex-shrink: 0;
// 				line-height: 1;
// 				overflow: hidden;
// 				user-select: none;
// 				transition: all 200ms ease-in-out 0s;
// 				border-radius: 0.75rem;
// 				background: transparent;
// 				box-shadow: rgba(0, 0, 0, 0.12) 0rem 0.3125rem 0.625rem 0rem;
// 				width: 4.625rem;
// 				height: 4.625rem;
// 				font-size: 1rem;
// 				margin-right: 24px;
// 				img {
// 					width: 100%;
// 					text-align: center;
// 					object-fit: cover;
// 					color: transparent;
// 					text-indent: 10000px;
// 					height: 100%;
// 				}
// 			}
// 			h5 {
// 				margin-bottom: 3px;
// 				font-size: 20px;
// 				line-height: 1.375;
// 				opacity: 1;
// 				text-transform: none;
// 				vertical-align: unset;
// 				text-decoration: none;
// 				color: $text-color;
// 				font-weight: 500;
// 			}
// 			span {
// 				margin: 0px;
// 				font-size: 13.5px;
// 				line-height: 1.5;
// 				letter-spacing: 0.02857em;
// 				opacity: 1;
// 				text-transform: none;
// 				vertical-align: unset;
// 				color: $text-muted;
// 				font-weight: 400;
// 				font-style: italic;
// 			}
// 			@include media-breakpoint-down(md) {
// 				align-items: flex-start;
// 				margin-bottom: 10px;
// 				.personal_avatar {
// 					width: 3.425rem;
// 					height: 3.425rem;
// 					margin-right: 15px;
// 				}
// 				h5 {
// 					font-size: 17px;
// 				}
// 				span {
// 					font-size: 12.8px;
// 				}
// 			}
// 			header {
// 				transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// 				display: flex;
// 				flex-direction: column;
// 				width: 100%;
// 				justify-content: flex-end;
// 				box-sizing: border-box;
// 				flex-shrink: 0;
// 				position: static;
// 				background-color: transparent;
// 				color: inherit;
// 				box-shadow: none;
// 				.nav_tab {
// 					overflow: hidden;
// 					display: flex;
// 					position: relative;
// 					border-radius: 0.75rem;
// 					min-height: unset;
// 					padding: 0.25rem;
// 					background: transparent;
// 					.nav {
// 						position: relative;
// 						flex: 1 1 auto;
// 						white-space: nowrap;
// 						width: 100%;
// 						overflow: unset !important;
// 						display: flex;
// 						height: 100%;
// 						position: relative;
// 						z-index: 10;
// 						.nav-item {
// 							.nav-link {
// 								outline: 0px;
// 								border: 0px;
// 								margin: 0px;
// 								cursor: pointer;
// 								user-select: none;
// 								text-align: center;
// 								font-size: 13px;
// 								font-weight: 400;
// 								padding: 4px 12px;
// 								color: $text-color !important;
// 								border-radius: 0.375rem;
// 								transition: all 100ms ease 0s;
// 								i { 
// 									margin-right: 0.375rem;
//     							margin-bottom: 0px !important;
// 									font-size: 14.4px;
// 									color: $text-color !important;
// 								}
// 								&.active {
// 									background-image: linear-gradient(310deg, #0b4ec1, #2087ee);
// 									background-position-y: initial;
// 									background-repeat: initial;
// 									background-attachment: initial;
// 									background-origin: initial;
// 									background-clip: initial;
// 									background-color: initial;
// 									box-shadow: $box-shadow;
// 									color: $white !important;
// 									i { 
// 										color: $gray-100 !important;
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }
// .personal_info_details {
// 	color: rgba(0, 0, 0, 0.87);
// 	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// 	overflow: hidden;
// 	display: flex;
// 	flex-direction: column;
// 	position: relative;
// 	min-width: 0px;
// 	overflow-wrap: break-word;
// 	background-color: $white;
// 	background-clip: border-box;
// 	border-radius: 1rem;
// 	box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
// 	height: 100%;
// 	.personal_info_details-header {
// 		display: flex;
//     -webkit-box-pack: justify;
//     justify-content: space-between;
//     -webkit-box-align: center;
//     align-items: center;
//     padding-top: 16px;
//     padding-left: 16px;
//     padding-right: 16px;
//     opacity: 1;
//     background: transparent;
// 		h6 {
// 			margin: 0px;
// 			font-size: 1rem;
// 			line-height: 1.625;
// 			letter-spacing: 0.0075em;
// 			text-transform: capitalize;
// 			color: $text-color;
// 			font-weight: 500;
// 		}
// 	}
// 	.personal_info_details-body {
// 		padding: 16px;
//     opacity: 1;
//     background: transparent;
// 		h6 {
// 			margin: 0px;
// 			font-size: 1rem;
// 			line-height: 1.625;
// 			letter-spacing: 0.0075em;
// 			text-transform: capitalize;
// 			color: $text-color;
// 			font-weight: 500;
// 		}
// 		.personal_info_details-admin {
// 			margin-bottom: 16px;
// 			line-height: 1;
// 			color: $text-color;
// 			span {
// 				font-size: $font-size-sm;
// 				line-height: 1.5;
// 				letter-spacing: 0.02857em;
// 				opacity: 1;
// 				color: $text-muted;
// 				font-weight: 400;
// 			}
// 		}
// 		.personal_info_details-list {
// 			display: flex;
// 			padding-top: 8px;
// 			padding-bottom: 8px;
// 			padding-right: 16px;
// 			opacity: 1;
// 			background: transparent;
// 			color: $text-color;
// 			span {
// 				margin: 0px;
// 				font-size: 0.875rem;
// 				line-height: 1.5;
// 				letter-spacing: 0.02857em;
// 				opacity: 1;
// 				text-transform: capitalize;
// 				vertical-align: unset;
// 				text-decoration: none;
// 				color: $text-color;
// 				font-weight: 700;
// 				&.name {
// 					font-size: 0.875rem;
// 					line-height: 1.5;
// 					text-transform: none;
// 					color: $text-muted;
// 					font-weight: 400;
// 				}
// 			}
// 		}
// 		.personal_info_details-edit-form {

// 		}
// 	}
	
// }

// /* ==================
// 	LOAN SECTION
// ====================*/
// .loan_form {
// 	.expected_return {
// 		display: flex;
// 		padding-top: 4px;
// 		padding-bottom: 4px;
// 		padding-right: 16px;
// 		opacity: 1;
// 		background: transparent;
// 		color: $text-color;
// 		span {
// 			margin: 0px;
// 			font-size: 0.875rem;
// 			line-height: 1.5;
// 			letter-spacing: 0.02857em;
// 			opacity: 1;
// 			text-transform: capitalize;
// 			vertical-align: unset;
// 			color: $text-color;
// 			font-weight: 300;
// 			&.name {
// 				font-size: 0.875rem;
// 				line-height: 1.5;
// 				letter-spacing: 0.02857em;
// 				color: $primary;
// 				font-weight: 400;
// 			}
// 		}
// 	}
// }
// .loan_history {
// 	.loan_history_item {
// 		.loan_history-inner {
// 			width: 100%;
// 			padding: 18px 24px;
// 			display: block;
// 			border-bottom: 1px solid $border-color;
// 			&:hover {
// 				background-color: $light;
// 			}
// 			h6 {
// 				color: rgb(52, 71, 103);
// 				margin: 0px;
// 				line-height: 1.25;
// 				margin-bottom: 8px;
// 				font-weight: 500;
// 				font-size: 15px;
// 				margin: 0px;
// 				text-transform: capitalize;
// 				vertical-align: unset;
// 			}
// 			.action_btn {
// 				display: flex;
// 				justify-content: flex-end;
// 				text-align: right;
// 				align-items: center;
// 				.badge {
// 					font-size: 13px;
// 				}
// 			}
// 			.loan_history-inner_line1 {
// 				display: flex;
// 				justify-content: space-between;
// 				margin-bottom: 4px;
// 			}
// 			.loan_history-inner_line2 {
// 				margin-bottom: 8px;
// 				line-height: 0;
// 				opacity: 1;
// 				background: transparent;
// 				span {
// 					margin: 0px;
// 					font-size: 0.85rem;
// 					font-weight: 400;
// 					line-height: 1.25;
// 					letter-spacing: 0.03333em;
// 					opacity: 1;
// 					text-transform: none;
// 					vertical-align: unset;
// 					text-decoration: none;
// 					color: $text-color;
// 					span {
// 						margin: 0px;
// 						font-size: 0.85rem;
// 						line-height: 1.25;
// 						letter-spacing: 0.03333em;
// 						opacity: 1;
// 						vertical-align: unset;
// 						text-decoration: none;
// 						color: $text-muted;
// 						font-weight: 400;
// 					}
// 				}
// 			}
// 		}
// 	}
// }
// .custom_card-body.commodity__loan {
// 	background-image: url(https://demos.creative-tim.com/soft-ui-dashboard-react/static/media/white-curved.a5b7db662b0b14ab5534.jpeg);
//   background-position: 100% center;
// 	background-size: cover;
// 	overflow: hidden;
// 	border: 1px solid $white;
// 	border-radius: 1rem;

// 	.loan_commodity-outter {
// 		margin-top: 0px;
//     margin-bottom: 0px;
//     position: relative;
//     z-index: 2;
//     width: 100%;
//     height: 100%;
// 		padding: 16px 24px;
// 		h5 {
// 			margin: 0px 0px 0.45em;
// 			font-size: 1.30rem;
// 			letter-spacing: 0.01071em;
// 			opacity: 1;
// 			color: $text-color;
// 			font-weight: 600;
// 			letter-spacing: 1px;
// 			text-transform: uppercase;
// 		}
// 		&::after{
// 			content: "";
// 			// background-image: linear-gradient(310deg, #e4edfb, #e4edfb);
// 			display: block;
// 			height: 100%;
// 			width: 100%;
// 			// border-radius: 1rem;
// 			position: absolute;
// 			top: 0px;
// 			left: 0px;
// 			opacity: 0.65;
// 			z-index: -1;
// 			// overflow: hidden;
// 		}
// 	}
// }
// .loan_commodity {
// 	margin: 4px;
// 	.image-container {
// 		height: 320px;
// 		width: 100%;
// 		border-radius: 0.75rem;
// 		overflow: hidden;
// 		cursor: pointer;
// 		border: 1px solid $gray-100;
// 		.img-commodity {
// 			width: 100%;
// 			height: 100%;
// 			object-fit: cover;
// 			object-position: 100%;
// 		}
// 	}
// 	.loan_commodity_leftside {
// 		display: flex;
// 		justify-content: space-between;
// 		align-items: flex-start !important;
// 		// margin-bottom: 5px;
// 		@include media-breakpoint-down(xl) {
// 			flex-direction: column;
// 		}

// 		.loan_commodity_leftside_box {
// 			display: flex;
// 			flex-direction: column;
// 			// height: 100%;
// 			opacity: 1;
// 			background: transparent;
// 			p.label {
// 				margin: 0px;
// 				line-height: 1.5;
// 				font-size: 17px;
// 				opacity: 1;
// 				color: $text-color;
// 			}
// 			p.amount {
// 				margin: 0px;
// 				line-height: 1.6;
// 				opacity: 1;
// 				font-size: 18px;
// 				color: $text-color;
// 				font-weight: 700;
// 			}
// 			p.interest {
// 				font-size: 12.3px;
// 				color: $text-muted;
// 			}
// 			a.link_more {
// 				margin: auto auto 0px 0px;
// 				line-height: 1.5;
// 				letter-spacing: 0.02857em;
// 				text-decoration: none;
// 				color: rgb(103, 116, 142);
// 				font-weight: 500;
// 				display: inline-flex;
// 				-webkit-box-align: center;
// 				align-items: center;
// 				cursor: pointer;
// 			}
// 		}
// 	}
// }

// .withdrawal_item {
// 	position: relative;
// 	&::after {
// 		content: "";
//     position: absolute;
//     top: 10%;
//     left: 14px;
//     height: 100%;
//     border-right: 2px solid $border-color;
// 	}
// 	.withdrawal_item-icon {
// 		width: 1.625rem;
//     height: 1.625rem;
//     position: absolute;
//     top: 3.25%;
//     left: 2px;
//     z-index: 2;
//     opacity: 1;
//     background: white;
//     border-radius: 50%;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		i {
// 			font-size: 18px;
// 			line-height: 1;
// 		}
// 	}
// 	.withdrawal_item-content {
// 		margin-left: 46px;
//     padding-top: 4px;
//     line-height: 0;
//     max-width: 30rem;
// 		margin-bottom: 18px;
// 		.content_title {
// 			font-size: 0.875rem;
// 			line-height: 1.5;
// 			letter-spacing: 0.02857em;
// 			opacity: 1;
// 			text-transform: none;
// 			vertical-align: unset;
// 			text-decoration: none;
// 			color: $text-color;
// 			font-weight: 500;
// 			display: flex;
// 			justify-content: space-between;
// 		}
// 		.content_date {
// 			margin-top: 4px;
// 			opacity: 1;
// 			span.date {
// 				margin: 0px;
// 				font-size: 0.75rem;
// 				line-height: 1.25;
// 				letter-spacing: 0.03333em;
// 				opacity: 1;
// 				text-transform: none;
// 				vertical-align: unset;
// 				text-decoration: none;
// 				color: $text-muted;
// 				font-weight: 400;
// 			}
// 		}
// 		// .content-bottom {
// 		// 	margin-top: 26px;
// 		// 	margin-bottom: 32px;
// 		// 	opacity: 1;
// 		// 	background: transparent;
// 		// }
// 	}
// }

// .slick-list {
// 	margin: 0 -10px;
// }
// .commodity_products_item {
// 	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// 	position: relative;
// 	min-width: 0px;
// 	overflow-wrap: break-word;
// 	background-color: rgb(255, 255, 255);
// 	background-clip: border-box;
// 	border: 0px solid rgba(0, 0, 0, 0.125);
// 	border-radius: 1rem;
// 	display: flex;
// 	flex-direction: column;
// 	box-shadow: none;
// 	overflow: visible;
// 	margin: 0 10px;
// 	.commodity_item_img {
// 		position: relative;
//     width: 100.25%;
// 		height: 210px;
//     opacity: 1;
//     background: transparent;
//     border-radius: 1rem;
//     box-shadow: rgba(20, 20, 20, 0.25) 0rem 1.4375rem 2.8125rem -0.6875rem;
// 		img {
// 			display: block;
// 			background-size: cover;
// 			background-repeat: no-repeat;
// 			background-position: center center;
// 			border-radius: 1rem;
// 			width: auto;
// 			max-width: 100%;
// 			width: 100%;
// 			max-height: 100%;
// 			margin: 0px;
// 			box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
// 			object-fit: cover;
// 			object-position: center center;
// 		}
// 	}
// 	.commodity_item_content {
// 		padding-top: 24px;
//     padding-left: 4px;
//     padding-right: 4px;
//     opacity: 1;
//     background: transparent;
// 		.c_item_1 {
// 			margin-bottom: 8px;
// 			opacity: 1;
// 			background: transparent;
// 			.catType {
// 				font-size: 0.875rem;
// 				line-height: 1.5;
// 				letter-spacing: 0.02857em;
// 				opacity: 1;
// 				text-transform: capitalize;
// 				vertical-align: unset;
// 				color: rgb(52, 71, 103);
// 				font-weight: 400;
// 				background-image: linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111));
// 				display: inline-block;
// 				background-clip: text;
// 				-webkit-text-fill-color: transparent;
// 				position: relative;
// 				z-index: 1;
// 				margin: 0px;
// 				text-decoration: none;
// 			}
// 			.title_name {
// 				margin: 0px;
// 				font-size: 1.25rem;
// 				line-height: 1.375;
// 				font-weight: 500;
// 				letter-spacing: 0em;
// 				opacity: 1;
// 				text-transform: capitalize;
// 				vertical-align: unset;
// 				text-decoration: none;
// 				color: $text-color
// 			}
// 		}
// 		.c_item_2 {
// 			margin-bottom: 24px;
// 			line-height: 0;
// 			opacity: 1;
// 			background: transparent;
// 			.paraph {
// 				margin: 0px;
// 				font-size: 0.799rem;
// 				line-height: 1.5;
// 				letter-spacing: 0.02857em;
// 				opacity: 1;
// 				text-transform: none;
// 				vertical-align: unset;
// 				text-decoration: none;
// 				color: $text-muted;
// 				font-weight: 400;
// 			}
// 		}
// 		.c_item_3 {
// 			display: flex;
// 			-webkit-box-pack: justify;
// 			justify-content: space-between;
// 			-webkit-box-align: center;
// 			align-items: center;
// 			opacity: 1;
// 			background: transparent;
// 			.commodity_buy_btn {
// 				position: relative;
// 				box-sizing: border-box;
// 				-webkit-tap-highlight-color: transparent;
// 				outline: 0px;
// 				margin: 0px;
// 				cursor: pointer;
// 				vertical-align: middle;
// 				appearance: none;
// 				text-decoration: none;
// 				letter-spacing: 0.02857em;
// 				min-width: 54px;
// 				border: 1px solid $primary;
// 				display: inline-flex;
// 				-webkit-box-pack: center;
// 				justify-content: center;
// 				-webkit-box-align: center;
// 				align-items: center;
// 				font-weight: 700;
// 				border-radius: 0.5rem;
// 				line-height: 1.4;
// 				text-align: center;
// 				text-transform: uppercase;
// 				user-select: none;
// 				transition: all 150ms ease-in 0s;
// 				min-height: 2.125rem;
// 				padding: 8px 28px;
// 				font-size: 0.75rem;
// 				background-image: initial;
// 				background-position-y: initial;
// 				background-repeat: initial;
// 				background-attachment: initial;
// 				background-origin: initial;
// 				background-clip: initial;
// 				background-color: transparent;
// 				color: $primary;
// 				background-size: 150% !important;
// 				background-position-x: 25% !important;
// 			}
// 			.commodity_amount {
// 				display: flex;
// 				opacity: 1;
// 				background: transparent;
// 				.amount {
// 					color: $text-color;
// 					font-weight: 500;
// 				}
// 			}
// 		}
// 	}
// }