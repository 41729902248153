.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}
.card .card-header .dropdown-toggle {
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
.card .card-header .dropdown {
  line-height: 1;
}

.card .card-header .dropdown .dropdown-menu {
  line-height: 1.5;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: $white;
  border-bottom: 1px solid $border-color;
}

.card-header:first-child {
  border-radius: calc($border-radius - 1px) calc($border-radius - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: $gray-100;
  border-top: 1px solid $border-radius;
}

.card-footer:last-child {
  border-radius: 0 0 calc($border-radius - 1px) calc($border-radius - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc($border-radius - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc($border-radius - 1px);
  border-top-right-radius: calc($border-radius - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc($border-radius - 1px);
  border-bottom-left-radius: calc($border-radius - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 0.75rem;
    margin-bottom: 0;
    margin-left: 0.75rem;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}



// Camera view box
.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
  width: 100% !important;
}

.drag-file-area {
  border: 2px dashed $gray-300;
	border-radius: 20px;
	margin: 0 0 15px;
	max-width: 280px;
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	text-align: center;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
	  border-radius: 15px;
  }
  .upload-icon {
    font-size: 50px;
  }
  .label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out; 
    overflow: hidden;
    input {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer !important;
    }
  }
}
.StepperContainer-0-2-1 {
  font-family: $font-family-base !important;
}
// .drag-file-area label {
// 	font-size: 16px;
// }
// .drag-file-area label .browse-files-text {
// 	color: #7b2cbf;
// 	font-weight: bolder;
// 	cursor: pointer;
// }
// .browse-files span {
// 	position: relative;
// 	top: -20px;
// }
// .default-file-input {
// 	opacity: 0.9;
//   // width: 80px;
// }