/* Custom Dark Mode Toggle Element */
.toggle-theme-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  span {
    font-size: 0.79rem;
  }

  .toggle-theme {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 44px;

    input {
      display: none;

      &:checked {
        + .slider:before {
          transform: translateX(22px);
        }

        + .slider {
          background-color: #75a0e6;
        }
      }
    }

    .slider {
      background-color: $gray-400;
      position: absolute;
      cursor: pointer;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      transition: 0.2s;

      &::before {
        background-color: #fff;
        bottom: 2px;
        content: "";
        height: 16px;
        width: 16px;
        left: 3px;
        position: absolute;
        transition: 0.4s;
      }
    }
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
}